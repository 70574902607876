import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./App.css";

const Home = () => <h1>Home</h1>;

const About = () => <h1>About</h1>;

const Page = ({ match }) => {
  const pageNumber =
    match.params.page.length === 1
      ? "0" + match.params.page
      : match.params.page;

  return (
    <div className="comicPageWrapper">
    <div className="comicPageContainer">
      <div className="comicNav">
        {((match.params.page || 1) > 1 || (match.params.issue || 1) > 1) && (
          <Link to={"/comics/" + match.params.comic + "/1/1"}>
            <img src={process.env.PUBLIC_URL + "/reverse.png"} alt="" />
          </Link>
        )}
        {(match.params.page || 1) > 1 && (
          <Link
            to={
              "/comics/" +
              match.params.comic +
              "/" +
              match.params.issue +
              "/" +
              (parseInt(match.params.page || 1) - 1)
            }
          >
            <img src={process.env.PUBLIC_URL + "/prev.png"} alt="" />
          </Link>
        )}
        <Link to="/">
          <img src={process.env.PUBLIC_URL + "/up.png"} alt="" />
        </Link>
        {match.params.page < 24 && (
          <Link
            to={
              "/comics/" +
              match.params.comic +
              "/" +
              match.params.issue +
              "/" +
              (parseInt(match.params.page || 1) + 1)
            }
          >
            <img src={process.env.PUBLIC_URL + "/next.png"} alt="" />
          </Link>
        )}
        {match.params.page == 24 && (
          <Link
            to={
              "/comics/" +
              match.params.comic +
              "/" +
              (parseInt(match.params.issue) + 1) +
              "/1"
            }
          >
            <img src={process.env.PUBLIC_URL + "/next.png"} alt="" />
          </Link>
        )}
        {(match.params.page || 1) < 24 && (match.params.issue || 1) < 5 && (
          <Link to={"/comics/" + match.params.comic + "/5/24"}>
            <img src={process.env.PUBLIC_URL + "/ff.png"} alt="" />
          </Link>
        )}
      </div>
      <div className="comicPageParent">
        <div className="comicPageImageContainer">
          <img
            className="comicPage"
            src={
              process.env.PUBLIC_URL +
              "/rEvolver" +
              match.params.issue +
              pageNumber +
              ".png"
            }
          />
        </div>
      </div>
    </div>
    </div>
  );
};

const Comic = ({ match }) => (
  <div className="comic-container">
    <Link to="/comics/revolver/1/1">
      <img
        className="comic-cover"
        src={process.env.PUBLIC_URL + "/rEvolver100.png"}
        alt=""
      />
    </Link>
    <Link to="/comics/revolver/2/1">
      <img
        className="comic-cover"
        src={process.env.PUBLIC_URL + "/rEvolver200.png"}
        alt=""
      />
    </Link>
    <Link to="/comics/revolver/3/1">
      <img
        className="comic-cover"
        src={process.env.PUBLIC_URL + "/rEvolver300.png"}
        alt=""
      />
    </Link>
    <Link to="/comics/revolver/4/1">
      <img
        className="comic-cover"
        src={process.env.PUBLIC_URL + "/rEvolver400.png"}
        alt=""
      />
    </Link>
    <Link to="/comics/revolver/5/1">
      <img
        className="comic-cover"
        src={process.env.PUBLIC_URL + "/rEvolver500.png"}
        alt=""
      />
    </Link>
  </div>
);

const Links = () => (
  <nav>
    <Link to="/">Home</Link>
    <Link to="/about">About</Link>
    <Link to="/comics">Comics</Link>
  </nav>
);

const Banner = () => (
  <div className="banner-container">
    <img
      className="banner"
      src={process.env.PUBLIC_URL + "/rEvolverlogocolorblack.jpg"}
      alt=""
    />
  </div>
);

function App() {
  return (
    <Router>
      <div>
        <Banner />
        <Route exact path="/" component={Comic} />
        <Route exact path="/comics/:comic/:issue/:page?" component={Page} />
      </div>
    </Router>
  );
}

export default App;
